<template>
	<div>
		<Header
			:showBackButton="true"
			:showLogo="false"
			:title="'Nova receita'"
			:menuColor="'#fff'"
			:background="'var(--blue1)'"
			class="page-anim1"
		/>

		<section>
			<div class="container recipe">
				<div class="tabs">
					<div
						@click="setTab('general')"
						class="tab"
						:class="{ active: activeTab == 'general' }"
					>
						Geral
					</div>
					<div
						@click="setTab('ingredients')"
						class="tab"
						:class="{ active: activeTab == 'ingredients' }"
					>
						Ingredientes
					</div>
				</div>

				<transition enter-active-class="animated fadeInLeft">
					<div v-show="activeTab == 'general'" class="user-data-holder mt-2">
						<div class="input-item">
							<span>Título *</span>
							<input v-model="recipe.title" type="text" />
						</div>

						<div class="input-item">
							<span>Descrição *</span>
							<textarea v-model="recipe.description"></textarea>
						</div>

						<div class="input-item">
							<span>Modo de preparo *</span>
							<textarea v-model="recipe.prepareMode"></textarea>
						</div>

						<div class="input-item">
							<span>Categoria</span>
							<select v-model="recipe.recipeCategoryId">
								<option value="">Selecione uma categoria</option>
								<option
									v-for="(category, index) in categories"
									:key="index"
									:value="category.id"
								>
									{{ category.name }}
								</option>
							</select>
						</div>

						<div class="input-item">
							<span>Adicionar imagens</span>
							<div class="file-holder">
								<ImageIcon />
								<p>Procurar imagens</p>
								<input
									@change="processFile($event)"
									ref="myFiles"
									type="file"
									multiple
									accept="image/png, image/jpeg, image/jpg"
								/>
							</div>
						</div>

						<div class="images-preview">
							<div
								v-for="(img, index) in recipe.recipeImage"
								:key="index"
								class="item"
							>
								<div class="img" :style="`background-image:url(${img.path})`"></div>
								<p @click="removeImage(index)">Remover</p>
							</div>
						</div>

						<div class="input-item privacy">
							<span>Essa receita é privada ou pública?</span>
							<div class="holder">
								<div
									@click="recipe.isPublic = false"
									class="opt"
									:class="{ active: !recipe.isPublic }"
								>
									<Lock />
									<p>Privada</p>
								</div>
								<div
									@click="recipe.isPublic = true"
									class="opt"
									:class="{ active: recipe.isPublic }"
								>
									<LockOpened />
									<p>Pública</p>
								</div>
							</div>
						</div>

						<div @click="setTab('ingredients')" class="control-btn btn border icon">
							<span>Ingredientes</span>
							<ChevronRight :size="26" />
						</div>

						<div v-if="!newRecipe" class="flex-center mt-2">
							<div @click="deleteRecipe" class="delete">Excluir esta receita</div>
						</div>
					</div>
				</transition>

				<transition enter-active-class="animated fadeInRight">
					<div
						v-show="activeTab == 'ingredients'"
						class="ingredients-tab-content mt-2"
					>
						<div class="input-item">
							<span>Calda base *</span>
							<select v-model="syrupSelected" @change="changeSyrup">
								<option value="">Não possui</option>
								<option value="custom">Personalizada</option>
								<option v-for="(syrup, index) in syrups" :key="index" :value="syrup">
									{{ syrup.name }}
								</option>
							</select>
							<div
								v-show="
									syrupSelected != null &&
									syrupSelected != '' &&
									syrupSelected != 'custom'
								"
								@click="syrupNameEditMode = !syrupNameEditMode"
								class="edit-name"
							>
								<p>Alterar nome</p>
								<PencilIcon fillColor="var(--secondary)" :size="20" />
							</div>
						</div>

						<transition enter-active-class="animated fadeInLeft">
							<div
								v-show="syrupNameEditMode || syrupSelected == 'custom'"
								class="input-item"
							>
								<span>Nome da calda base *</span>
								<input v-model="allIngredients.syrup.name" type="text" />
							</div>
						</transition>

						<transition enter-active-class="animated zoomIn">
							<div
								v-show="
									allIngredients.syrup.ingredients.length > 0 ||
									syrupSelected == 'custom'
								"
								class="ingredients-holder p-b"
							>
								<div class="ingredient head">
									<p style="font-family: fontBold">Ingredientes da calda</p>
									<p>Qtde</p>
								</div>
								<div
									v-for="(ingredient, index) in allIngredients.syrup.ingredients"
									:key="index"
									class="ingredient"
								>
									<div class="item name">
										<img v-show="ingredient.image" :src="ingredient.image" />
										<v-select
											taggable
											v-model="ingredient.name"
											:options="userIngredients"
											:reduce="(userIngredients) => userIngredients.name"
											label="name"
										/>
									</div>
									<div class="item">
										<input v-model="ingredient.quantity" type="number" />
										<span v-show="!ingredient.new">{{ ingredient.measurementUnit }}</span>
										<select
											v-show="ingredient.new"
											v-model="ingredient.measurementUnit"
											style="margin-left: 4px"
										>
											<option v-for="unit in measurementUnits" :key="unit" :value="unit">
												{{ unit }}
											</option>
										</select>
									</div>
									<div class="action">
										<RemoveIcon
											@click="removeIngredient('syrup', index)"
											:fillColor="'var(--secondary)'"
											class="pointer"
										/>
									</div>
								</div>
								<div @click="addIngredient('syrup')" class="recipe-btn">
									<PlusIcon :size="26" :fillColor="'var(--secondary)'" />
									<p>Adicionar ingrediente</p>
								</div>
							</div>
						</transition>

						<transition enter-active-class="animated zoomIn">
							<div v-show="syrupSelected != null" class="ingredients-holder p-b">
								<div class="ingredient head">
									<p style="font-family: fontBold; margin-right: 10px">
										Ingredientes Saborização
									</p>
									<p>Qtde</p>
								</div>
								<div
									v-for="(ingredient, index) in allIngredients.flavoring"
									:key="index"
									class="ingredient"
								>
									<div class="item name">
										<img v-show="ingredient.image" :src="ingredient.image" />
										<v-select
											taggable
											v-show="!ingredient.siberProduct"
											v-model="ingredient.name"
											:options="userIngredients"
											:reduce="(userIngredients) => userIngredients.name"
											label="name"
										/>
										<select
											v-show="ingredient.siberProduct"
											v-model="ingredient.productId"
											@change="setIngredient(ingredient)"
										>
											<option v-for="prod in products" :key="prod.id" :value="prod.id">
												{{ prod.name }}
											</option>
										</select>
									</div>
									<div class="item">
										<input v-model="ingredient.quantity" type="number" />
										<span v-show="!ingredient.new">{{ ingredient.measurementUnit }}</span>
										<select
											v-show="ingredient.new"
											v-model="ingredient.measurementUnit"
											style="margin-left: 4px"
										>
											<option v-for="unit in measurementUnits" :key="unit" :value="unit">
												{{ unit }}
											</option>
										</select>
									</div>
									<div class="action">
										<RemoveIcon
											@click="removeIngredient('flavoring', index)"
											:fillColor="'var(--secondary)'"
											class="pointer"
										/>
									</div>
								</div>
								<div class="btns-holder">
									<div @click="addIngredientSiber('flavoring')" class="recipe-btn">
										<PlusIcon :size="26" :fillColor="'var(--secondary)'" />
										<p>Ingrediente Siber</p>
									</div>
									<div @click="addIngredient('flavoring')" class="recipe-btn">
										<PlusIcon :size="26" :fillColor="'var(--secondary)'" />
										<p>Outro ingrediente</p>
									</div>
								</div>
							</div>
						</transition>

						<transition enter-active-class="animated zoomIn">
							<div v-show="syrupSelected != null" class="ingredients-holder">
								<div class="ingredient head">
									<p style="font-family: fontBold">Outros ingredientes</p>
									<p>Qtde</p>
								</div>
								<div
									v-for="(ingredient, index) in allIngredients.ingredients"
									:key="index"
									class="ingredient"
								>
									<div class="item name">
										<img v-show="ingredient.image" :src="ingredient.image" />
										<v-select
											taggable
											v-show="!ingredient.siberProduct"
											v-model="ingredient.name"
											:options="userIngredients"
											:reduce="(userIngredients) => userIngredients.name"
											label="name"
										/>
										<select
											v-show="ingredient.siberProduct"
											v-model="ingredient.productId"
											@change="setIngredient(ingredient)"
										>
											<option v-for="prod in products" :key="prod.id" :value="prod.id">
												{{ prod.name }}
											</option>
										</select>
									</div>
									<div class="item">
										<input v-model="ingredient.quantity" type="number" />
										<span v-show="!ingredient.new">{{ ingredient.measurementUnit }}</span>
										<select
											v-show="ingredient.new"
											v-model="ingredient.measurementUnit"
											style="margin-left: 4px"
										>
											<option v-for="unit in measurementUnits" :key="unit" :value="unit">
												{{ unit }}
											</option>
										</select>
									</div>
									<div class="action">
										<RemoveIcon
											@click="removeIngredient('ingredients', index)"
											:fillColor="'var(--secondary)'"
											class="pointer"
										/>
									</div>
								</div>
								<div class="btns-holder">
									<div @click="addIngredientSiber('ingredients')" class="recipe-btn">
										<PlusIcon :size="26" :fillColor="'var(--secondary)'" />
										<p>Ingrediente Siber</p>
									</div>
									<div @click="addIngredient('ingredients')" class="recipe-btn">
										<PlusIcon :size="26" :fillColor="'var(--secondary)'" />
										<p>Outro ingrediente</p>
									</div>
								</div>
							</div>
						</transition>

						<transition enter-active-class="animated zoomIn">
							<div v-show="syrupSelected != null" class="input-item yield">
								<span>Quanto rende esta receita? *</span>
								<div class="holder">
									<input v-model.number="recipe.yield" type="number" />
									<select v-model="recipe.measurementUnit" style="margin-left: 4px">
										<option v-for="unit in measurementUnits" :key="unit" :value="unit">
											{{ unit }}
										</option>
									</select>
								</div>
							</div>
						</transition>

						<transition enter-active-class="animated zoomIn">
							<div v-show="syrupSelected != null" class="input-item">
								<span>Inclusão de Ar (%)</span>
								<div class="holder">
									<input v-model.number="allIngredients.overrun" type="number" />
								</div>
							</div>
						</transition>

						<transition enter-active-class="animated zoomIn">
							<div
								v-show="syrupSelected != null"
								class="flex-between btn-final-holder"
							>
								<div @click="setTab('general')" class="btn border back">
									<ChevronLeft :size="26" :fillColor="'var(--secondary)'" />
									<span>Voltar</span>
								</div>
								<div @click="save" class="btn save">
									<div v-show="saving" class="loading2 white"></div>
									<span v-show="!saving">Finalizar</span>
									<div v-show="!saving">
										<ChevronRight :size="26" :fillColor="'#fff'" />
									</div>
								</div>
							</div>
						</transition>
					</div>
				</transition>
			</div>
		</section>
	</div>
</template>
<script>
	import http from "@/http";
	import authentication from "../util/authentication";
	import Header from "../components/Header";
	import PlusIcon from "vue-material-design-icons/PlusCircleOutline.vue";
	import ImageIcon from "vue-material-design-icons/ImageOutline.vue";
	import Lock from "vue-material-design-icons/LockOutline.vue";
	import LockOpened from "vue-material-design-icons/LockOpenVariantOutline.vue";
	import RemoveIcon from "vue-material-design-icons/MinusCircleOutline.vue";
	import PencilIcon from "vue-material-design-icons/PencilOutline.vue";
	import ChevronRight from "vue-material-design-icons/ChevronRight.vue";
	import ChevronLeft from "vue-material-design-icons/ChevronLeft.vue";

	export default {
		name: "Home",
		components: {
			Header,
			PlusIcon,
			ImageIcon,
			Lock,
			LockOpened,
			RemoveIcon,
			PencilIcon,
			ChevronRight,
			ChevronLeft,
		},
		data() {
			return {
				loading: true,
				saving: false,
				activeTab: "general",
				newRecipe: true,
				syrupNameEditMode: false,
				userIngredients: [],
				categories: [],
				products: [],
				syrups: [],
				syrupSelected: null,
				recipe: {
					title: "",
					description: "",
					prepareMode: "",
					yield: 0,
					measurementUnit: "gr",
					isPublic: false,
					ingredients: {},
					recipeImage: [],
					recipeCategoryId: "",
				},
				allIngredients: {
					overrun: 0,
					ingredients: [],
					syrup: {
						id: null,
						name: "",
						ingredients: [],
					},
					flavoring: [],
				},
				ingredient: {
					name: "",
					quantity: "",
					measurementUnit: "gr",
					price: "0",
					slug: "",
					productId: "",
					image: "",
				},
				measurementUnits: ["gr", "ml"],
			};
		},
		created() {
			if (this.$route.params.id !== "nova") {
				this.newRecipe = false;
				this.getRecipe();
			} else {
				this.getSyrups();
			}

			this.getIngredients();
			this.getCategories();
			this.getProducts();
		},
		methods: {
			processFile() {
				this.loading = true;
				var vm = this; // vue model;
				if (this.$refs.myFiles.files.length > 0) {
					let files = [...this.$refs.myFiles.files];
					files.forEach((file) => {
						var reader = new FileReader();
						reader.readAsDataURL(file);
						reader.onload = function (event) {
							if (event.target.readyState == FileReader.DONE) {
								vm.loading = false;
								vm.selectedImage = event.target.result;
								vm.recipe.recipeImage.push({
									path: event.target.result,
								});
							}
						};
					});
					vm.loading = false;
				} else {
					vm.loading = false;
				}
			},
			insert() {
				this.saving = true;
				http
					.post("recipe", this.recipe, authentication.getHeaders())
					.then((response) => {
						if (response.status === 200) {
							this.$swal({
								toast: true,
								icon: "success",
								title: "Receita cadastrada!",
								position: "top-right",
								showConfirmButton: false,
								timer: 4000,
							});
							this.$router.push("/perfil?recipe=true");
						}
					})
					.catch((err) => {
						console.log(err);
					})
					.finally(() => {
						this.saving = false;
					});
			},
			update() {
				this.saving = true;
				http
					.put("recipe", this.recipe, authentication.getHeaders())
					.then((response) => {
						if (response.status === 200) {
							this.$swal({
								toast: true,
								icon: "success",
								title: "Receita atualizada!",
								position: "top-right",
								showConfirmButton: false,
								timer: 4000,
							});
							this.$router.push("/perfil?recipe=true");
						}
					})
					.catch((err) => {
						console.log(err);
					})
					.finally(() => {
						this.saving = false;
					});
			},
			save() {
				if (
					this.allIngredients.ingredients.length == 0 &&
					this.allIngredients.flavoring.length == 0 &&
					this.allIngredients.syrup.ingredients.length == 0
				) {
					this.showWarning(
						"Ingredientes *",
						"Por favor, informe pelo menos um ingrediente para sua receita"
					);
					return;
				}

				if (this.recipe.yield == 0) {
					this.showWarning(
						"Rendimento da Receita *",
						"Por favor, informe o quanto rende esta receita"
					);
					return;
				}

				this.allIngredients.ingredients.forEach((i) => {
					if (i.new) i.new = false;
				});
				this.allIngredients.flavoring.forEach((i) => {
					if (i.new) i.new = false;
				});
				this.allIngredients.syrup.ingredients.forEach((i) => {
					if (i.new) i.new = false;
				});

				this.recipe.ingredients = JSON.stringify(this.allIngredients);

				this.newRecipe ? this.insert() : this.update();
			},
			deleteRecipe() {
				this.$swal
					.fire({
						icon: "error",
						title: "Apagar receita",
						text: `Tem certeza que deseja apagar esta receita? Esta ação não poderá ser desfeita.`,
						confirmButtonText: "Apagar",
						confirmButtonColor: "var(--red1)",
						showCancelButton: true,
					})
					.then((result) => {
						if (result.value) {
							http
								.delete(`recipe/${this.recipe.id}`, authentication.getHeaders())
								.then(() => {
									this.$swal({
										toast: true,
										icon: "success",
										title: "Receita excluída!",
										position: "top-right",
										showConfirmButton: false,
										timer: 4000,
									});
									this.$router.push("/perfil?recipe=true");
								});
						}
					});
			},
			changeSyrup() {
				if (this.syrupSelected != "") {
					this.allIngredients.syrup.name = this.syrupSelected.name;
					this.allIngredients.syrup.slug = this.syrupSelected.slug;
					if (this.syrupSelected?.ingredients) {
						this.allIngredients.syrup.ingredients = JSON.parse(
							this.syrupSelected.ingredients
						);
					}
					this.lock = true;
				} else {
					this.allIngredients.syrup.name = "";
					this.allIngredients.syrup.ingredients = [];
				}

				if (this.syrupSelected != "custom") {
					this.syrupNameEditMode = false;
				}
			},
			setIngredient(ingredient) {
				let prod = this.products.find((prod) => prod.id === ingredient.productId);
				if (!prod) return;

				ingredient.name = prod.name;
				ingredient.image = prod.image;
				ingredient.slug = prod.slug;
				ingredient.productId = prod.id;
			},
			addIngredientSiber(target) {
				this.ingredient = {
					name: "",
					quantity: "",
					measurementUnit: "gr",
					price: "0",
					slug: "",
					productId: "",
					image: "",
				};
				this.ingredient.siberProduct = true;
				this.ingredient.new = true;

				if (target == "flavoring") {
					this.allIngredients.flavoring.push(this.ingredient);
				}
				if (target == "ingredients") {
					this.allIngredients.ingredients.push(this.ingredient);
				}
			},
			addIngredient(target) {
				this.ingredient = {
					name: "",
					quantity: "",
					measurementUnit: "gr",
					price: "0",
					slug: "",
					productId: "",
					image: "",
				};
				this.ingredient.id = new Date().getTime();
				this.ingredient.new = true;
				if (target == "syrup") {
					this.allIngredients.syrup.ingredients.push(this.ingredient);
				}
				if (target == "flavoring") {
					this.allIngredients.flavoring.push(this.ingredient);
				}
				if (target == "ingredients") {
					this.allIngredients.ingredients.push(this.ingredient);
				}
			},
			removeIngredient(target, index) {
				if (target == "syrup") {
					this.allIngredients.syrup.ingredients.splice(index, 1);
				}
				if (target == "flavoring") {
					this.allIngredients.flavoring.splice(index, 1);
				}
				if (target == "ingredients") {
					this.allIngredients.ingredients.splice(index, 1);
				}
			},
			removeImage(index) {
				this.recipe.recipeImage.splice(index, 1);
			},
			showWarning(title, text) {
				this.$swal({
					icon: "warning",
					title: title,
					text: text,
				});
			},
			setTab(tab) {
				if (tab === "general") this.activeTab = tab;

				if (tab === "ingredients") {
					if (!this.recipe.title) {
						this.showWarning(
							"Título *",
							"Por favor, informe um título para a receita"
						);
						return;
					}
					if (!this.recipe.description) {
						this.showWarning(
							"Descrição *",
							"Por favor, informe uma descrição para a receita"
						);
						return;
					}
					if (!this.recipe.prepareMode) {
						this.showWarning(
							"Modo de preparo *",
							"Por favor, informe o modo de preparo"
						);
						return;
					}

					this.activeTab = tab;
				}
			},
			getIngredients() {
				this.loading = true;

				const params = {
					page: 0,
					take: -1,
					orderBy: "-name",
					ingredients: [],
				};

				http
					.post("ingredient/list", params, authentication.getHeaders())
					.then((response) => {
						this.userIngredients = response.data.list;
					})
					.catch((err) => {
						console.log(err);
					})
					.finally(() => {
						this.loading = false;
					});
			},
			getCategories() {
				this.loading = true;
				http
					.get(
						"recipeCategory/list?orderby=name-&take=-1",
						authentication.getHeaders()
					)
					.then((response) => {
						this.categories = response.data.list;
					})
					.catch((err) => {
						console.log(err);
					})
					.finally(() => {
						this.loading = false;
					});
			},
			getProducts() {
				this.loading = true;
				http
					.get(`product/list?orderBy=-name&take=-1`, authentication.getHeaders())
					.then((response) => {
						this.products = response.data.list;
					})
					.catch((err) => {
						console.log(err);
					})
					.finally(() => {
						this.loading = false;
					});
			},
			getSyrups() {
				this.loading = true;
				http
					.get(`syrup/list?orderBy=-name&take=-1`, authentication.getHeaders())
					.then((response) => {
						this.syrups = response.data.list;
						if (this.allIngredients.syrup.name != "") {
							this.syrupSelected =
								this.syrups.filter(
									(s) => s.name == this.allIngredients.syrup.name
								)[0] || "custom";
							this.syrupNameEditMode = true;
						} else {
							this.syrupSelected = "";
						}
					})
					.catch((err) => {
						console.log(err);
					})
					.finally(() => {
						this.loading = false;
					});
			},
			getRecipe() {
				this.loading = true;
				http
					.get(
						`recipe/details?id=${this.$route.params.id}`,
						authentication.getHeaders()
					)
					.then((response) => {
						this.recipe = response.data;
						localStorage.ingredients = this.recipe.ingredients;
						if (this.recipe.ingredients) {
							this.allIngredients = JSON.parse(this.recipe.ingredients);
							if (!this.allIngredients.syrup?.name)
								this.allIngredients.syrup.name = "";
							this.getSyrups();
						}
					})
					.catch((err) => {
						console.log(err);
					})
					.finally(() => {
						this.loading = false;
					});
			},
		},
	};
</script>
<style lang="scss" scoped>
	.container.recipe {
		width: 100%;
	}

	.tabs {
		position: relative;
		width: 100%;
		max-width: 600px;
		display: grid;
		grid-template-columns: 1fr 1fr;
		margin: 25px auto 0 auto;
		border-bottom: 1px solid #ccc;
		.tab {
			position: relative;
			display: grid;
			place-items: center;
			height: 38px;
			font-size: 1.15em;
			cursor: pointer;
			&.active {
				color: var(--secondary);
				font-family: fontMedium;
				&::after {
					position: absolute;
					content: "";
					width: 100%;
					height: 1px;
					background-color: var(--secondary);
					left: 0;
					bottom: -1px;
				}
			}
		}
	}

	.user-data-holder {
		position: relative;
		width: 90%;
		max-width: 600px;
		display: flex;
		flex-direction: column;
		margin: 0 auto;
	}

	.title {
		position: relative;
		color: var(--dark4);
		margin: 15px 0 0 0;
	}

	.btns-holder {
		position: relative;
		width: 100%;
		max-width: 500px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin: 5px auto 0 auto;
	}

	.recipe-btn {
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
		margin: 15px auto 0 auto;
		cursor: pointer;
		p {
			font-size: 1.15em;
			margin: -2px 0 0 10px;
			color: var(--secondary);
		}
	}

	.control-btn {
		border-color: var(--secondary);
		max-width: 300px;
		margin: 35px auto 0 auto;
		span,
		span svg {
			color: var(--secondary);
			font-family: fontLight;
		}
		span svg {
			position: relative;
			top: 2px;
			margin-left: 25px;
		}
	}

	.edit-name {
		position: relative;
		display: flex;
		justify-content: flex-start;
		align-items: center;
		margin-top: 3px;
		cursor: pointer;
		p {
			color: var(--secondary);
			margin-right: 10px;
		}
	}

	.input-item {
		margin-top: 22px;
		input,
		select {
			border-bottom-color: #ddd;
			font-size: 1.25em;
			font-family: fontLight;
			padding-left: 0;
		}
		textarea {
			position: relative;
			width: 100%;
			height: 62px;
			padding: 8px 0;
			font-family: fontLight;
			font-size: 1.25em;
			border-bottom: 1px solid #ddd;
			resize: none;
		}
		.file-holder {
			position: relative;
			width: 100%;
			height: 36px;
			display: flex;
			justify-content: flex-start;
			align-items: center;
			border-bottom: 1px solid #ddd;
			margin: 5px auto 0 auto;
			p {
				font-family: fontLight;
				margin-left: 15px;
				font-size: 1.15em;
			}
			input {
				position: absolute;
				width: 100%;
				height: 100%;
				top: 0;
				left: 0;
				opacity: 0;
				cursor: pointer;
			}
		}
	}

	.images-preview {
		position: relative;
		width: 100%;
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		align-items: center;
		margin: 10px -5px 0 -5px;
		.item {
			position: relative;
			display: flex;
			flex-direction: column;
			margin: 5px;
			.img {
				position: relative;
				width: 70px;
				height: 60px;
				background-repeat: no-repeat;
				background-position: center;
				background-size: cover;
			}
			p {
				margin-top: 5px;
				text-align: center;
				cursor: pointer;
			}
		}
	}

	.input-item.privacy {
		span {
			text-align: center;
		}
		.holder {
			position: relative;
			width: 100%;
			max-width: 500px;
			margin: 0 auto;
			display: flex;
			justify-content: center;
			align-items: center;
			.opt {
				position: relative;
				display: flex;
				justify-content: center;
				align-items: center;
				border-bottom: 1px solid #ddd;
				padding: 4px 8px;
				margin: 15px 20px;
				cursor: pointer;
				&.active {
					border-color: var(--secondary);
					p,
					span svg {
						color: var(--secondary);
					}
				}
				p {
					font-size: 1.1em;
					margin-left: 10px;
				}
			}
		}
	}

	.ingredients-tab-content {
		position: relative;
		width: 95%;
		max-width: 600px;
		margin: 0 auto;
	}

	.ingredients-holder {
		position: relative;
		width: 100%;
		padding: 1.2em 4%;
		margin: 22px auto 0 auto;
		border-radius: 14px;
		box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.12);
		.ingredient {
			position: relative;
			width: 100%;
			display: grid;
			grid-template-columns: 45% 1fr 1fr 30px;
			margin-top: 10px;
			@media only screen and (max-width: 720px) {
				grid-template-columns: 66% 90px 30px;
			}
			&.head p {
				font-size: 1.1em;
			}
			.item {
				position: relative;
				width: 100%;
				height: 32px;
				display: flex;
				justify-content: flex-start;
				align-items: center;
				padding-right: 15px;
				img {
					position: relative;
					max-width: 16px;
					max-height: 100%;
				}
				&::before {
					position: absolute;
					content: "";
					width: calc(100% - 15px);
					height: 1px;
					background-color: #ddd;
					left: 0;
					bottom: -1px;
				}
				input,
				select {
					position: relative;
					width: 100%;
					min-width: 36px;
					height: 100%;
					color: var(--primary);
				}
			}
			.action {
				position: relative;
				height: 100%;
				display: grid;
				place-items: center;
			}
		}
	}

	.btn-final-holder {
		position: relative;
		width: 100%;
		margin: 45px auto 0 auto;
		flex-wrap: nowrap;
	}

	.btn.border.back {
		border-color: var(--secondary);
		padding: 15px 29px;
		span {
			font-family: fontLight;
			color: var(--secondary);
		}
		span.material-design-icon {
			position: relative;
			top: 2px;
			margin-right: 12px;
			margin-left: -6px;
		}
	}

	.btn.save {
		padding: 15px 29px;
		span.material-design-icon {
			position: relative;
			top: 2px;
			margin-left: 12px;
			margin-right: -6px;
		}
	}

	.input-item.yield {
		max-width: 600px;
		margin: 35px auto 0 auto;
		.holder {
			display: flex;
			justify-content: space-between;
			align-items: center;
			input {
				margin-right: 1.2em;
			}
			select {
				width: 10%;
				min-width: 36px;
				max-width: 100px;
			}
		}
	}

	.delete {
		font-size: 1.1em;
		padding: 1em 0.5em;
		color: rgb(224, 23, 23);
		cursor: pointer;
	}
</style>
